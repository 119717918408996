@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Lato:wght@300;400;700&family=Montserrat&display=swap');

:root {
--background-color-1: #31446C;
--background-color-2: #666AF6;
--background-color-2-t: rgba(102, 107, 246, 0.5);
--background-color-3: #F6F7EB;
--background-color-4: #e94f37;
--background-color-5: #44BBA4;
--overlay-1: #379B87;
--overlay-2: #050D0B;
--background-color-contact: hsla(238, 89%, 68%, 0.05);
--color-1: #FBF8F8;
--color-2: #333333;
--color-3: #676666;
--color-4: #E1E4E5;
--color-5: #31446C;
--color-6: #fff;
--color-7: #E94F37;

--line-color-1: #E94F37;
--line-color-2: #707070;
--line-color-3: rgba(112, 112, 112, 0.23);
--shadow-1: rgba(102, 107, 246, 0.16);
--transparent-1: rgba(225, 228, 229, 0.16);

--lato: "Lato", sans-serif;
--montserrat: 'Montserrat', sans-serif;
--abril: 'Abril Fatface', cursive;
--size-hero: 8rem;
--size-h1: 8rem;
--size-h3: 3rem;
--weight-hero: 700;
--weight-h1: 700;
--weight-h2: 400;
--spacing-h: 2px;
--spacing-p: 1px;
--hero-button-height: 6rem;
--hero-subheading-max-width: 90rem;
--hero-pad-left: 10rem;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    margin: 0 auto;
    background-color: var(--background-color-3);
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    /* max-width: 192rem; */
}

#app {
    margin: 0 auto;
}

/* Laptop XL 1920*/
@media screen and (max-width: 1920px) {
    :root {
        --size-hero: 6rem;
        --size-h1: 6rem;
        --size-h3: 2rem;
        --hero-button-height: 5.5rem;
        --hero-subheading-max-width: 70rem;
    }
}

/* Laptop L 1440*/
@media screen and (max-width: 1440px) {
    :root {
        --size-hero: 5rem;
        --size-h1: 5rem;
        --size-h3: 2rem;
    }
}

/* Laptop 1024*/
@media screen and (max-width: 1024px) {
    :root {
        --size-hero: 4.5rem;
        --size-h1: 5rem;
        --size-h3: 1.8rem;
    }
}

/* Tablet */
@media screen and (max-width: 768px) {
    
}

/* Mobile L */
@media screen and (max-width: 425px) {
    
}

/* Mobile M */
@media screen and (max-width: 375px) {
    
}